<template>
  <v-card :dark="$dark.get()">
    <loader v-if="loading" />
    <div class="custom-card">
      <!-- Заголовок страницы -->
      <div class="custom-card__title">
        <v-btn color="primary" @click="$router.go(-1)">
          <v-icon left>mdi-arrow-left-circle</v-icon>
          вернуться
        </v-btn>
        <p class="custom-card__text">Профиль сотрудника</p>
      </div>
      <!-- блок информации основной -->
      <section v-if="employee" class="custom-card__main">
        <div v-if="employee.imageUrl" class="mb-5">
          <v-img :src="employee.imageUrl" width="200" />
        </div>
        <p>
          <span class="font-weight-black">Имя: </span>{{ employee.username }}
        </p>
        <p>
          <span class="font-weight-black">Номер телефона: </span
          >{{ employee.phone }}
        </p>
        <p><span class="font-weight-black">Роль: </span>{{ employee.role }}</p>
        <p>
          <span class="font-weight-black">Смен отработанно: </span
          >{{ employee.workedDays.length }}
        </p>
        <p>
          <span class="font-weight-black">Часов отработанно: </span
          >{{ employee.hoursWorked }}
        </p>
        <p>
          <span class="font-weight-black">Кол-Во собранных товаров: </span
          >{{ employee.pickedItems }}
        </p>
        <p>
          <span class="font-weight-black"
            >Начисление за отработанные часы: </span
          >{{ employee.salaryByGraph }}
        </p>
        <div>
          <span class="font-weight-black">Минус вознаграждение: </span
          >{{ employee.totalPenalty.totalSum }}
          <v-btn icon color="success" @click="penaltyModal = true">
            <v-icon>mdi-plus-circle</v-icon>
          </v-btn>
          <v-btn icon color="primary" @click="penaltyDetailsModal = true">
            <v-icon>mdi-dots-horizontal-circle</v-icon>
          </v-btn>
        </div>
        <p>
          <span class="font-weight-black">Премия: </span>{{ employee.reward }}
        </p>
        <p>
          <span class="font-weight-black">Начисления за отдельный период: </span
          >{{ employee.endSalary }}
        </p>
      </section>
    </div>
    <!-- модалка с добавлением штрафа -->
    <v-dialog v-model="penaltyModal" width="80%">
      <v-card class="modal" :dark="$dark.get()">
        <v-card-title> Добавить штраф </v-card-title>
        <v-card-text v-if="employee" class="modal__text">
          <v-form ref="form">
            <v-text-field
              v-model="input.reason"
              label="Причина"
              :rules="[$validate.required]"
            />
            <v-text-field
              v-model="input.sum"
              type="number"
              label="Сумма"
              :rules="[$validate.required]"
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="penaltyModal = false">Закрыть</v-btn>
          <v-btn color="primary" @click="addPenalty()">Создать</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- модалка со списком штрафов -->
    <v-dialog v-model="penaltyDetailsModal" width="80%">
      <v-card class="modal" :dark="$dark.get()">
        <v-card-title> Все штрафы сотрудника </v-card-title>
        <v-card-text v-if="employee" class="modal__text">
          <div
            v-for="item in employee.totalPenalty.penaltyList"
            :key="item.id"
            class="penalty"
          >
            <p>
              <span class="font-weight-black">Название: </span>{{ item.reason }}
            </p>
            <p><span class="font-weight-black">Сумма: </span>{{ item.sum }}</p>
            <p>
              <span class="font-weight-black">Время: </span
              >{{ getDate(item.penaltyTime) }}
            </p>
            <v-btn
              color="warning"
              small
              class="mb-2"
              @click="downloadPenalty(item.id)"
              >Скачать накладную</v-btn
            >
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="penaltyDetailsModal = false"
            >Закрыть</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from "moment";
import loader from "@/components/Loader";
import showMessage from "@/Functions/message";
import downloadFile from "@/Functions/downloadFile";
import api from "@/api";

export default {
  components: {
    loader,
  },
  data() {
    return {
      loading: false,
      penaltyModal: false,
      penaltyDetailsModal: false,
      employee: null,
      input: {},
    };
  },
  mounted() {
    this.setData();
  },
  methods: {
    //Подгружает профиль
    async setData() {
      this.loading = true;
      const response = await api.Employee.getProfile(this.$route.params.id);
      if (response.type !== "error") {
        this.employee = response;
      }
      this.loading = false;
    },
    async addPenalty() {
      if (!this.$refs.form.validate()) {
        showMessage("Заполните все поля");
        return;
      }
      this.input.employeeId = this.$route.params.id;
      const response = await api.Employee.addPenalty(this.input);
      if (response.type !== "error") {
        this.downloadPenalty(response);
        showMessage("Штраф добавлен", true);
        this.setData();
        this.penaltyModal = false;
      }
    },
    getDate(val) {
      return moment(val).format("DD.MM.YY HH:mm");
    },
    async downloadPenalty(id) {
      const response = await api.Penalty.downloadPenalty(id);
      if (response.status !== 200) {
        showMessage("Ошибка скачивания файла");
        return;
      }
      downloadFile(
        response.data,
        `Докладная по штрафу №${id}, ${this.employee.username}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-card {
  padding: 40px;
  position: relative;
  &__main {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__btns {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  &__btn {
    width: 80%;
  }
  &__title {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    display: flex;
    gap: 20px;
    align-items: center;
    p {
      margin-bottom: 0;
    }
  }
}
.penalty {
  padding: 3px 20px;
  border: 1px solid red;
  border-radius: 20px;
  margin-bottom: 5px;
}
</style>
